import axios from 'axios';
import URL from '../url/urls.js';

const getFetch = async (url, body = null) => {
    if (!url) return null;

    try {
        if (body) {
            body.VF_API_KEY = process.env.VF_API_KEY;
        }

        const { data } = await axios.post(url, body || {}, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return data;
    } catch (e) {
        console.warn('e: ', e);
        return null;
    }
};

const commaValue = (v, round = false, fixed = 0) => {
    let result = v;
    try {
        if (round) {
            result = Math.round(result);
        }

        if (fixed) {
            result = result.toFixed(fixed);
        }

        result = result.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } catch (e) {
        console.warn(e);
    }

    return result;
};

const modifyTier = async (param) => {
    const { name, email, gid, cancel = false } = param;

    const { data: tiers } = await axios.get(URL.TIER);

    let tier = null;

    if (!tiers || !Array.isArray(tiers)) return false;

    for (let i = 0; i < tiers.length; i += 1) {
        if (tiers[i].name === name) {
            tier = tiers[i];
        }
    }

    const options = {};
    if (tier) {
        options.tier = JSON.stringify(tier);
    }

    if (gid) {
        options.gid = gid;
    }

    options.email = email;
    options.cancelAtEnd = cancel ? 1 : 0;

    const subscribeData = await getFetch(
        `${URL.SERVER}/app_update_subscription`,
        options
    );

    return !!(subscribeData && subscribeData.success);
};

export default {
    getFetch,
    modifyTier,
    commaValue
};
