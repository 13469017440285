import { useState, useEffect } from 'react';
import YouTube from 'react-youtube';

import { Spinner, Button, Link, TextStyle, TextField, Select } from "@shopify/polaris";
import { useAuthenticatedFetch } from "../hooks";

import URL from '../../url/urls';

const SCRIPT_ID_LENGTH = 24;

export default function Help({scriptTags : {edges = []} = {}}) {
    const fetch = useAuthenticatedFetch();

    const [options, setOptions] = useState([]);
    const [mobile, setMobile] = useState(false);
    const [scriptId, setScriptId] = useState('');
    const [status, checkStatus] = useState(null);
    const [selectedTutorialId, setSelectedTutorialId] = useState(null);

    useEffect(async () => {
        const response = await fetch(URL.TUTORIAL);
        let list = [];

        if(response && response.ok) {
            const data = await response.json();
            for (let i = 0; i < data.length; i += 1) {
                const { title, videoId, videoMobileId, shopifyOrder } = data[i];
                const id = mobile ? videoMobileId : videoId;
                list.push({
                    label: title,
                    value: id,
                    shopifyOrder,
                });
            }
        
            if (list.length > 0) {
                list = list.sort((a, b) => a.shopifyOrder > b.shopifyOrder);
                list = list.map((entry) => {
                    return { value: entry.value, label: entry.label };
                });
            }

            setOptions(list);
        }

        const setResponsiveness = () => {
            setMobile(window.innerWidth < 900);
        };

        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness());

        return () => {
            window.removeEventListener('resize', () => setResponsiveness());
        };
    }, []);

    const handleChange = (newValue) => {
        setScriptId(newValue)
    };

    const onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    };

    const fixScriptIssue = async () => {
        checkStatus({
            success: true,
            loading: true,
            message: 'Loading...',
        });

        let resultId = null;
        let success = true;
        let message = '';

        const scriptTagUrl = `https://product.vizframe.com/shopify-script/${scriptId}.js`;

        for (let i = 0; i < edges.length; i++) {
            const { node } = edges[i];

            if (
                node &&
                node.id &&
                node.src &&
                node.src.indexOf('vizframe.com') >= 0
            ) {
                // if resultId is set in the previous for loop run (i >= 1)
                if (resultId) {
                    const options = {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ type: 'delete', id: node.id })
                    };

                    await fetch('/api/tag', options);
                } else {
                    resultId = node.id;

                    const options = {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ type: 'update', id: node.id, src: scriptTagUrl})
                    };

                    const updateInfo = await fetch('/api/tag', options);

                    if(!updateInfo) {
                        success = false;
                        message = 'It appears something has gone wrong. Please check your SCRIPT ID or contact support@vizframe.com for assistance';
                    }
                    else {
                        const json = await updateInfo.json();
                        if (json && json.result && json.result.scriptTagUpdate && json.result.scriptTagUpdate.scriptTag) {
                            success = true;
                            message = 'Success. Wait 15 minutes and verify your store.';
                        }
                        else {
                            success = false;
                            message = 'It appears something has gone wrong. Please check your SCRIPT ID or contact support@vizframe.com for assistance';
                        }
                    }
                }
            }
        }

        if (!resultId) {
            const options = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ type: 'create', src: scriptTagUrl })
            };

            const createInfo = await fetch('/api/tag', options);

            if(!createInfo) {
                success = false;
                message = 'It appears something has gone wrong. Please contact support@vizframe.com for assistance.';
            }
            else {
                const json = await createInfo.json();
                if (json && json.result && json.result.scriptTagCreate && json.result.scriptTagCreate.scriptTag) {
                    success = true;
                    message = 'Success. Wait 15 minutes and verify your store.';
                }
                else {
                    success = true;
                    message = 'It appears something has gone wrong. Please contact support@vizframe.com for assistance.';
                }
            }
        }

        checkStatus({ success, message, loading: false });
    };

    return (
        <div className="entry">
            <p className="entry">
                Not sure where to start?
            </p>
            {options.length > 0 && (
                <div className='entry'>
                    <div className="tutorialDropdown">
                        <Select
                            label="Select tutorial"
                            options={options}
                            onChange={(value) => {
                                setSelectedTutorialId(value);
                            }}
                            value={selectedTutorialId || options[0].value}
                        />
                    </div>

                    <YouTube
                        key={selectedTutorialId || options[0].value}
                        opts={{
                            height: 250,
                            width: mobile ? '100%' : 400,
                            playerVars: {
                                autoplay: 0,
                            },
                        }}
                        videoId={selectedTutorialId || options[0].value}
                        onReady={onReady}
                    />
                </div>
            )}

            <div className="border" style={{margingTop: 30}} />

            <div className="entry">
                <div className="entry">
                    <p variation="strong">
                        Your VizFrame is having some trouble with your theme?
                        Lets Fix It!
                    </p>

                    <div className="entry" style={{ display: 'flex' }}>
                        <TextField
                            placeholder={`FixIt Script Id (min ${SCRIPT_ID_LENGTH} char)`}
                            value={scriptId}
                            onChange={handleChange}
                            autoComplete="off"
                        />

                        <span style={{ marginLeft: 15 }}>
                            {
                            status && status.loading ?
                            <Spinner accessibilityLabel="Updating script tag" size="small"/>
                            :
                            <Button
                                primary
                                disabled={
                                    !scriptId ||
                                    scriptId.length < SCRIPT_ID_LENGTH
                                }
                                onClick={() => {
                                    try {
                                        fixScriptIssue();
                                    }
                                    catch(e) {
                                        console.warn('e: ', e);
                                        checkStatus({ success: false, message: 'It appears something has gone wrong. Please contact support@vizframe.com for assistance.', loading: false });
                                    }
                                }}
                            >
                                Update
                            </Button>
                        }
                        </span>
                    </div>

                    {status && status.message && (
                        <TextStyle
                            variation={status.success ? 'positive' : 'negative'}
                        >
                            {status.message}
                        </TextStyle>
                    )}
                </div>
            </div>

            <div className="border" />

            <div className="entry">
                <p>
                    For further assistance,&nbsp;
                    <Link url="https://vizframe.com/help-center" external>
                        visit VizFrame.com&apos;s Help Center.
                    </Link>
                </p>
            </div>
        </div>
    );
}
