import { useState, useEffect } from 'react';

import {
    Spinner,
    Button,
    Layout,
    Card,
    Stack,
    TextField,
    ButtonGroup,
    List,
    Modal,
    Frame,
    Loading
} from "@shopify/polaris";

import LIB from '../../helpers/lib';
import URL from '../../url/urls';

import { useAuthenticatedFetch } from "../hooks";

const TYPE = {
    MONTHLY: 1,
    ANNUALLY: 0,
};

export default function Plan({shop : {email = null} = {}}) {
    const fetch = useAuthenticatedFetch();

    const [tierLevels, setTierLevels] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userTier, setUserTier] = useState();
    const [discount, setDiscount] = useState();
    const [type, setType] = useState(TYPE.ANNUALLY);
    const [promoCode, setPromoCode] = useState('');
    const [message, setMessage] = useState();
    const [warning, setWarning] = useState(null);
    const [promos, setPromos] = useState([]);

    useEffect(() => {
        retrieveUserData();
        loadPromo();
        getTiers();
    }, []);

    useEffect(() => {
        setWarning(null);
        setLoading(false);
    }, [message]);

    const retrieveUserData = async () => {
        try {
            const options = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            };
    
            const userTierInfo = await fetch('/api/getUserTier', options);

            if (!userTierInfo) return;

            const json = await userTierInfo.json();

            if (json && json.success && json.result) {
                setUserTier(json.result);
            }
        } catch (e) {
            console.warn('Can`t retrieve user data');
        }
        setLoading(false);
    };

    const getTiers = async () => {
        try {
            const res = await fetch(URL.TIER);
            const json = await res.json();

            if (json && Array.isArray(json)) {
                setTierLevels(json);
            }
        } catch (e) {
            console.warn('Tiers', e);
        }
    };

    const loadPromo = async () => {
        try {
            const res = await fetch(URL.PROMO);
            const json = await res.json();

            const arr = [];

            for (const key in json) {
                arr.push(json[key]);
            }

            setPromos(arr);
        } catch (e) {
            console.warn('Promos', e);
        }
    };

    const applyPromoCode = () => {
        setMessage(null);
        for (let i = 0; i < promos.length; i += 1) {
            const { code, expiry } = promos[i];
            if (promoCode === code) {
                if (expiry) {
                    try {
                        const date = new Date(expiry);
                        if (date.getTime() < Date.now()) {
                            setMessage(`Your code has expired on ${expiry}`);
                            return;
                        }
                    } catch (e) {
                        console.warn(e);
                    }
                }
                setDiscount(promos[i]);
                setMessage('Success!');
                return;
            }
        }
        setMessage('Please check your promo code and try again');
    };

    const cancelPlan = async () => {
        setLoading(true);
        try {
            const options = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            };
            const userData = await fetch('/api/getUserData', options); 
            const obj = await userData.json();
    
            if (obj && obj.result && obj.result.gid && obj.result.tier) {
                const planOptions = {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        type: 'cancel',
                        param: {
                            id: obj.result.gid
                        }
                    })
                };
            
                const data = await fetch('/api/updatePlan', planOptions);
    
                const { result = {} } = await data.json();
                const {
                    appSubscription,
                } = result.appSubscriptionCancel;
    
                if (appSubscription && appSubscription.status === 'CANCELLED') {
                    const options = {
                        method: 'post',
                        body: JSON.stringify({
                            email,
                            gid: obj.result.gid,
                            name: obj.result.tier.name,
                            cancel: true,
                        }),
                    };
                    const res = await fetch('/api/setUserTier/', options);
                    const result = await res.json();
    
                    if (result && result.success) {
                        setUserTier(null);
                        setMessage(
                            'Your account will be deleted at the end of your billing cycle.',
                        );
                    }
                }
            }
        }
        catch(e) {
            setMessage(
                'It appears something has gone wrong. Please contact support@vizframe.com for assistance.',
            );
            return;
        }

        setMessage('');
    };

    const addPlan = async (name, price) => {
        setLoading(true);

        const options = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: 'add',
                param: {
                    lineItems: {
                        plan: {
                            appRecurringPricingDetails: {
                                price: {
                                    amount:
                                        type === TYPE.ANNUALLY
                                            ? (price / 100) * 12
                                            : price / 100,
                                    currencyCode: 'USD',
                                },
                                interval:
                                    type === TYPE.ANNUALLY
                                        ? 'ANNUAL'
                                        : 'EVERY_30_DAYS',
                            },
                        },
                    },
                    name,
                    returnUrl: 'https://vizframe.com/shopify-3d-models'
                }
            })
        };

        const data = await fetch('/api/updatePlan', options);

        const subscription = await data.json();
        if (!subscription || !subscription.result || !subscription.result.appSubscriptionCreate) {
            setMessage('Error. Please check your payment info and try again');
            return;
        }

        const { appSubscription, confirmationUrl, userErrors } = subscription.result.appSubscriptionCreate;

        if(userErrors && Array.isArray(userErrors) && userErrors.length > 0) {
            console.warn(JSON.stringify(userErrors));
        }

        if (!appSubscription) {
            setMessage('Error. Please check your payment info and try again');
            return;
        }

        const { id, status } = appSubscription;

        if (!status) {
            setMessage('Error. Please check your payment info and try again');
            return;
        }

        switch (status.toLowerCase()) {
            case 'pending':
                if (id && id.split('/').length > 0) {
                    const chargeId = id.split('/').pop();

                    const options = {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            email,
                            chargeId: chargeId,
                        }),
                    };
                    await fetch('/api/setChargeId', options);
                }

                window.top.location = confirmationUrl;
                // window.open(confirmationUrl, '_blank');
                return;
            case 'accepted':
            case 'active':
                // const data = await LIB.modifyTier(
                //   tier,
                //   email,
                //   type,
                //   cancel
                // );
                // if (data) {
                //   setMessage("Thanks for subscribing");
                // } else {
                //   setMessage(
                //     "Something went wrong, please try again"
                //   );
                // }
                // successs
                break;
            case 'declined':
                setMessage('Your payment was declined');
                break;
            default:
                setMessage(
                    'It appears something has gone wrong. Please check your payment info and try again',
                );
        }
    };

    const selectFreeTier = async () => {
        setLoading(true);

        if(tierLevels.length > 0) {
            const options = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    email,
                    name: tierLevels[0].name,
                    cancel: false
                })
            };
            const result = await fetch('/api/setUserTier', options);
            if (result) {
                setWarning(null);
                setLoading(false);
                setUserTier(tierLevels[0]);
                return;
            }
        }

        setMessage('It appears something has gone wrong. Please contact support@vizframe.com for assistance.');
    };
    
    const subscriptionAction = async ({ currentPlanName, name, price }) => {
        const cancel = currentPlanName === name ? 1 : 0;

        if (cancel) {
            setWarning({
                title: `Your are about to delete your account. Do you want to continue?`,
                action: {
                    content: 'Delete',
                    onAction: () => {
                        cancelPlan();
                    },
                },
            });
            return;
        }

        if (price === 0) {
            setWarning({
                title: `You are selecting our FREE tier which supports only ${tierLevels[0].maxModel} projects. Do you want to continue?`,
                action: {
                    content: 'Continue',
                    onAction: () => {
                        selectFreeTier();
                    },
                },
            });
            return;
        }

        addPlan(name, price);
    };

    if (tierLevels === null) {
        return (
            <Spinner accessibilityLabel="Small spinner example" size="small" />
        );
    }

    return (
        <div className="entry">
           <Layout>
                <Stack distribution="fill">
                    <ButtonGroup
                        fullWidth={false}
                        segmented={true}
                        connectedTop={false}
                    >
                        <Button
                            outline={type !== TYPE.MONTHLY}
                            primary={type === TYPE.MONTHLY}
                            onClick={() => {
                                setType(TYPE.MONTHLY);
                            }}
                        >
                            Monthly
                        </Button>
                        <Button
                            outline={type !== TYPE.ANNUALLY}
                            primary={type === TYPE.ANNUALLY}
                            onClick={() => {
                                setType(TYPE.ANNUALLY);
                            }}
                        >
                            Annually
                        </Button>
                    </ButtonGroup>
                </Stack>
            </Layout>

            <div className="entry">
                <Layout>
                    <Stack distribution="fill">
                        {tierLevels.map((tier) => {
                            const {
                                name,
                                sub,
                                feature,
                                amount,
                                maxView,
                            } = tier;

                            let currentPlanName = null;
                            if (userTier && userTier.name) {
                                currentPlanName = userTier.name;
                            }

                            const finalPrices = amount.map((amt) => {
                                if (discount && discount.amount) {
                                    if (discount.type === 'percentage') {
                                        amt *= (100 - discount.amount) / 100;
                                    } else {
                                        amt -= discount.amount;
                                    }

                                    amt = Math.max(amt, 0);
                                }

                                return amt;
                            });

                            let price = finalPrices[type];
                            let original = null;

                            if (finalPrices[type] > 0) {
                                if (discount && discount.amount) {
                                    original = `$${(amount[type] / 100).toFixed(
                                        2,
                                    )}/m`;
                                }
                            }

                            const saving =
                                ((finalPrices[TYPE.MONTHLY] -
                                    finalPrices[TYPE.ANNUALLY]) *
                                    12) /
                                100;

                            let subtitle = sub;
                            if (saving) {
                                if (type === TYPE.ANNUALLY) {
                                    subtitle = `Save $${saving.toFixed(
                                        2,
                                    )} a year`;
                                } else {
                                    subtitle = `$${(
                                        finalPrices[TYPE.ANNUALLY] / 100
                                    ).toFixed(2)}/m if billed yearly`;
                                }
                            }

                            const content =
                                currentPlanName === name
                                    ? 'Cancel'
                                    : 'Subscribe';

                            return (
                                <Layout.Section key={name}>
                                    <Card
                                        title={name}
                                        sectioned
                                        primaryFooterAction={{
                                            loading: loading,
                                            content: content,
                                            destructive:
                                                currentPlanName === name,
                                            onAction: () => {
                                                subscriptionAction({
                                                    currentPlanName,
                                                    name,
                                                    price,
                                                });
                                            },
                                        }}
                                        footerActionAlignment="left"
                                    >
                                        {original && (
                                            <strong className="originalPrice">
                                                {original}
                                            </strong>
                                        )}
                                        {price > 0 ? (
                                            <strong>{`$${(price / 100).toFixed(
                                                2,
                                            )}/m`}</strong>
                                        ) : (
                                            <strong>FREE</strong>
                                        )}
                                        {subtitle && <p>{subtitle}</p>}
                                        <br></br>
                                        <List>
                                            <List.Item>{`${LIB.commaValue(
                                                maxView,
                                            )} Views / m`}</List.Item>
                                            {feature.map((text) => {
                                                return (
                                                    <List.Item key={text}>
                                                        {text}
                                                    </List.Item>
                                                );
                                            })}
                                        </List>
                                    </Card>
                                </Layout.Section>
                            );
                        })}
                    </Stack>
                </Layout>
            </div>

            <div className="entry">
                <Layout>
                    <Stack distribution="fill">
                        <TextField
                            placeholder="Promo code"
                            value={promoCode}
                            onChange={(v) => {
                                setPromoCode(v);
                                setMessage(null);
                            }}
                            autoComplete="off"
                        />

                        <Button disabled={!promoCode} onClick={applyPromoCode}>
                            Apply
                        </Button>
                    </Stack>
                </Layout>
            </div>

            <Modal
                open={!!message}
                loading={loading}
                onClose={() => {
                    setMessage(null);
                }}
                title={message}
            ></Modal>

            {warning && (
                <Modal
                    open={!!warning}
                    loading={loading}
                    primaryAction={loading ? null : warning.action}
                    onClose={() => {
                        setWarning(null);
                    }}
                    title={warning.title}
                ></Modal>
            )}

            {loading && (
                <Frame>
                    <Loading />
                </Frame>
            )}
        </div>
    );
}
