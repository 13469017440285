import { useState, useCallback } from 'react';
import { Card, Tabs, Spinner } from "@shopify/polaris";
import { useAppQuery, useAuthenticatedFetch } from "../hooks";

import Home from './Home';
import Help from './Help';
import Plan from './Plan';

import './main.css';
import '@shopify/polaris/build/esm/styles.css';

export default function IndexPage() {
    const fetch = useAuthenticatedFetch();

    const [selected, setSelected] = useState(0);
    const [queryLoading, setQueryLoading] = useState(true);

    const { data } = useAppQuery({
        url: "/api/admin/info", 
        reactQueryOptions: {
            onSuccess: ({ result }) => {
                if(result) {
                    const { scriptTags } = result;
                    let vfTagExists = false;
                    
                    if(scriptTags && Array.isArray(scriptTags.edges)){
                        for(let i = 0; i < scriptTags.edges.length; i += 1) {
                            const { node } = scriptTags.edges[i];

                            if(
                                node && node.src &&
                                node.src.indexOf('vizframe.com') >= 0 &&
                                node.src.indexOf('vizframe.com') >= 0
                            ) {
                                vfTagExists = true;
                            }
                        }
                    }

                    if (!vfTagExists) {
                        const options = {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                type: 'create',
                                src: 'https://product.vizframe.com/shopify-script/vizframe-default.js'
                            })
                        };

                        fetch('/api/tag', options);
                    }
                }

                setQueryLoading(false);
            }
        }
    });

    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        [],
    );

    const tabs = [
        {
            id: 'home-page',
            content: 'Home',
            accessibilityLabel: 'Home',
            panelID: 'home-page-content',
        },
        {
            id: 'plans',
            content: 'Plans',
            accessibilityLabel: 'Plans',
            panelID: 'plans-content',
        },
        // {
        //   id: 'promos',
        //   content: 'Promos',
        //   accessibilityLabel: 'Promos',
        //   panelID: 'promos-content',
        // },
        {
            id: 'help-center',
            content: 'Help Center',
            accessibilityLabel: 'Help Center',
            panelID: 'help-center-content',
        },
        // {
        //   id: 'account',
        //   content: 'Account',
        //   accessibilityLabel: 'Account',
        //   panelID: 'account-content',
        // }
    ];

    if(queryLoading) {
        return <Spinner accessibilityLabel="Small spinner example" size="small" />;
    }

    let result = {};

    if(data && data.result) {
        result = data.result;
    }

    return (
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
            <Card.Section title={tabs[selected].content}>
                {selected === 0 && <Home {...result} />}
                {selected === 1 && <Plan {...result} />}
                {selected === 2 && <Help {...result} />}
            </Card.Section>
        </Tabs>
    );
}
