import { useState } from 'react';

import { Spinner, Button, Link, TextStyle } from "@shopify/polaris";
import { useAuthenticatedFetch } from "../hooks";


export default function Home({shop : {email = null} = {}}) {
    const fetch = useAuthenticatedFetch();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const subscribeAttempt = async () => {
        setMessage('');
        setError('');

        setLoading(true);
        try {
            const options = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            };
    
            const subscribeInfo = await fetch('/api/subscribe', options);

            if (!subscribeInfo) {
                setError(
                    'It appears something has gone wrong. Please contact support@vizframe.com for assistance.',
                );
                return;
            }

            const json = await subscribeInfo.json();

            if (json.success) {
                setMessage(
                    'Thanks for signing up for our newsletter and welcome to the VizFrame community! Your promo code should arrive in your inbox shortly.',
                );
            } else if (json.error === 'ERROR_CONTACT_EXISTS') {
                setError(
                    "It looks like you're already a subscriber. If you have not been receiving our emails, please check your Spam/Junk folder and please add whatsup@vizframe.com to your safe senders list, address book, or contact list.",
                );
            } else {
                setError('It appears something has gone wrong. Please contact support@vizframe.com for assistance.');
            }
        } catch (err) {
            console.warn('err', err);
            setError('It appears something has gone wrong. Please contact support@vizframe.com for assistance.');
        }
        setLoading(false);
    };

    return (
        <>
            <div className="entry">
                <p className="entry">
                    Sign up for our newsletter and stay up to date with feature
                    upgrades, news, and promotions. . . you&apos;ll instantly
                    receive 10% off your first annual subscription.
                </p>
                <div className="entry">
                    {loading && <Spinner accessibilityLabel="Gatering email information" size="small" />}
                    <p style={{ paddingBottom: 10 }}>{email}</p>
                    <Button disabled={loading} onClick={subscribeAttempt}>
                        Subscribe
                    </Button>
                </div>

                {message && <TextStyle variation="positive">{message}</TextStyle>}
                {error && <TextStyle variation="negative">{error}</TextStyle>}
            </div>

            <div className="border" />

            <div className="entry">
                <p style={{ marginBottom: 10 }}>
                    <strong>NEXT STEP</strong>
                </p>

                <p>
                    <Link url="https://vizframe.com/shopify-3d-models" external>
                        Visit VizFrame.com
                    </Link>
                    &nbsp;and change your password. This will also activate your
                    account if you have not done it already.
                </p>
            </div>
        </>
    );
}
